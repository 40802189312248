import React, { useEffect, useMemo } from 'react';

import { handleSection } from '~/widgets/sections';
import { navigate } from 'gatsby';
import isEmpty from 'lodash.isempty';

export const useRenderSections = ({ sections, locale = null, url = null, meta = {} }) => {
  useEffect(() => {
    if (isEmpty(sections)) navigate('/404');
  }, []);
  const renderSections = useMemo(
    () =>
      sections.map(({ title: blockTitle, subTitle, block: blocks, id }) => {
        const block = blocks[0];
        if (!block?.__typename) {
          console.warn('[UNKNOWN] Block not available. Check schemas', blockTitle, subTitle);
          return <></>;
        }
        const Section = handleSection(block.__typename);
        return (
          <Section
            key={block.__typename}
            blockTitle={blockTitle}
            subTitle={subTitle}
            sectionId={id}
            {...block}
            locale={locale}
            url={url}
            meta={meta}
          />
        );
      }),
    [sections]
  );
  return {
    renderSections,
  };
};

export const useBuildMeta = ({ edges }) => {
  const {
    metaTitle = null,
    metaDescription = null,
    keywords = null,
    metaRobots = null,
    metaSocial = [],
  } = useMemo(() => edges?.[0]?.node.seo || {}, [edges?.[0]?.node]);

  return {
    metaTitle,
    metaDescription,
    keywords,
    metaRobots,
    metaSocial,
  };
};
