import React from 'react';

import { Container } from '~/shared/components';
import clsx from 'clsx';
import { Link } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';

import * as styles from './breadcrumbs.module.scss';

const Breadcrumbs = React.memo(({ children, className }) => (
  <Container className={clsx(styles.breadcrumb, className)}>
    <nav>{children}</nav>
  </Container>
));

export const Crumb = React.memo(({ link, active, children }) => (
  <span
    className={clsx(styles.breadcrumb, styles.breadcrumb__item)}
    aria-label="breadcrumb"
    aria-current={active ? 'page' : 'false'}
  >
    <Link
      to={link}
      className={clsx(styles.breadcrumb, styles.breadcrumb__link, active && styles.breadcrumb__linkActive)}
    >
      {children}
    </Link>
  </span>
));
export const Separator = () => <span className={clsx(styles.breadcrumb, styles.breadcrumb__item__separator)}> / </span>;

Breadcrumbs.propTypes = {
  children: PropTypes.object,
};

Crumb.propTypes = {
  link: PropTypes.string.isRequired,
  active: PropTypes.bool,
  children: PropTypes.object,
};

Breadcrumbs.Crumb = Crumb;
Breadcrumbs.Separator = Separator;
export default Breadcrumbs;
